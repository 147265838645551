:root {
  --success: #00c300;
  --error-color: red;
  /* --primary-font-color: black; */
  --std-border: 1px solid;
  --profile-segment-bg-color: rgba(232, 232, 232, 1); /* grey */
}

html[data-theme="tu"] {
  --primary-theme-color: #90419a;
  --primary-font-color: white;
  --secondary-font-color: #90419a;
  --header-font-color: #004884;
  --primary-theme-chat: rgba(217, 117, 217);
  --primary-theme-chat-light: rgba(217, 117, 217, 0.6);
  --primary-theme-chat-half-light: rgba(217, 117, 217, 0.5);
  --chat-bg: #f2e8f3;
  --oa-bg-color: rgba(217, 117, 217, 0.2);
  --oa-bg-color-light: rgba(217, 117, 217, 0.1);
  --diag-form-label-bg-color: #01b2ac3d;
  --diag-form-label-font-color: #00798a;
  --std-border: 0;
  --profile-segment-bg-color: rgba(178, 93, 210, 0.1);
  --diag-rule-header-font-color: #bdbdbd;
  --secondary-bg-color: rgba(232, 232, 232, 0.3);
  --consent-form-bg-color: white;
}

html[data-theme="egat"] {
  --primary-theme-color: #1ABDD4;
  --primary-font-color: white;
  --secondary-font-color: #333333;
  --header-font-color: #004884;
  --primary-theme-chat: rgba(26, 189, 212);
  --primary-theme-chat-light: rgba(26, 189, 212, 0.6);
  --primary-theme-chat-half-light: rgba(26, 189, 212 0.5);
  --chat-bg: #e8f5fd;
  --oa-bg-color: rgba(26, 189, 212 0.2);
  --oa-bg-color-light: rgba(26, 189, 212 0.1);
  --diag-form-label-bg-color: #01b2ac3d;
  --diag-form-label-font-color: #00798a;
  --std-border: 0;
  --profile-segment-bg-color: rgba(178, 93, 210, 0.1);
  --diag-rule-header-font-color: #bdbdbd;
  --secondary-bg-color: rgba(255, 204, 0, 0.3);
  --consent-form-bg-color: white;
}

html[data-theme="ishealth-mobile"] {
  --primary-theme-color: #1ABDD4;
  --primary-font-color: #515E63;
  --secondary-font-color: #333333;
  --header-font-color: #004884;
  --primary-theme-chat: rgba(26, 189, 212);
  --primary-theme-chat-light: rgba(26, 189, 212, 0.6);
  --primary-theme-chat-half-light: rgba(26, 189, 212 0.5);
  --chat-bg: #e8f5fd;
  --oa-bg-color: rgba(26, 189, 212 0.2);
  --oa-bg-color-light: rgba(26, 189, 212 0.1);
  --diag-form-label-bg-color: #01b2ac3d;
  --diag-form-label-font-color: #00798a;
  --std-border: 0;
  --profile-segment-bg-color: rgba(178, 93, 210, 0.1);
  --diag-rule-header-font-color: #bdbdbd;
  --secondary-bg-color: rgba(255, 204, 0, 0.3);
  --consent-form-bg-color: white;
}

/* html[data-theme="Mobegat"] {
  --primary-theme-color: #184193;
  --primary-font-color: white;
  --header-font-color: #004884;
  --primary-theme-chat: rgba(134, 199, 239);
  --primary-theme-chat-light: rgba(134, 199, 239, 0.6);
  --primary-theme-chat-half-light: rgba(134, 199, 239 0.5);
  --chat-bg: #e8f5fd;
  --oa-bg-color: rgba(134, 199, 239 0.2);
  --oa-bg-color-light: rgba(134, 199, 239, 0.1);
  --diag-form-label-bg-color: #01b2ac3d;
  --diag-form-label-font-color: #00798a;
  --std-border: 0;
  --profile-segment-bg-color: rgba(178, 93, 210, 0.1);
  --diag-rule-header-font-color: #bdbdbd;
  --secondary-bg-color: rgba(255, 204, 0, 0.3);
  --consent-form-bg-color: white;
} */

html[data-theme="penta"] {
  --primary-theme-color: #5dbcd2;
  --primary-font-color: white;
  --header-font-color: #004884;
  --primary-theme-chat: rgba(118, 207, 227);
  --primary-theme-chat-light: rgba(118, 207, 227, 0.6);
  --primary-theme-chat-half-light: rgba(118, 207, 227, 0.5);
  --chat-bg: rgba(1, 157, 154, 0.2);
  --oa-bg-color: rgba(1, 178, 172, 0.2);
  --oa-bg-color-light: rgba(1, 178, 172, 0.1);
  --diag-form-label-bg-color: #01b2ac3d;
  --diag-form-label-font-color: #00798a;
  --std-border: 0;
  --profile-segment-bg-color: rgba(93, 188, 210, 0.1);
  --diag-rule-header-font-color: #bdbdbd;
  --secondary-bg-color: rgba(232, 232, 232, 0.3);
  --consent-form-bg-color: white;
}

html[data-theme="penguin"] {
  --primary-theme-color: #0147a3;
  --primary-font-color: white;
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --profile-segment-bg-color: rgba(35, 153, 229, 0.1);

  --primary-theme-chat: rgba(35, 153, 229, 0.5);
  --chat-bg: rgba(35, 153, 229, 0.1);

  --std-border: 0;

  --oa-bg-color: rgba(35, 153, 229, 0.2);
  --oa-bg-color-light: rgba(35, 153, 229, 0.1);

  --diag-form-label-bg-color: rgba(35, 153, 229, 0.2);

  --secondary-bg-color: rgba(232, 232, 232, 0.3);
  --consent-form-bg-color: #cedbed;
}

.required {
  color: red;
}

.menu-bar.ui.menu {
  margin: 0;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--primary-theme-color, white);
  color: var(--primary-font-color);
  border-bottom: var(--std-border);
}

.menu-bar.ui.menu.oa-list {
  width: 100vw;
  overflow: hidden;
}

.menu-bar.ui.menu.oa-list .item.chat-channel-name {
  width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-bar.ui.menu .item.chat-channel-name {
  color: var(--primary-font-color);
}

.menu-bar.ui.menu.oa-list .menu-bar-name {
  width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-bar.ui.menu i {
  color: var(--primary-font-color);
}

.menu-bar.ui.menu .right-item {
  margin: 0px;
}

.menu-bar.ui.menu.chat-box {
  z-index: 10;
  /* position: fixed; */
  width: 100%;
}

.menu-bar.ui.menu.chat-box .chat-channel-name {
  text-align: center;
  width: 60%;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  color: var(--primary-font-color);
  overflow: hidden;
}

.menu-bar.ui.menu .menu-bar-name {
  color: var(--primary-font-color);
  cursor: pointer;
}

.menu-bar.ui.menu .page-name {
  text-align: center;
  width: 60%;
  color: var(--primary-font-color);
  display: flex;
  justify-content: center;
}

/* ========================================= Login ========================================= */

.Login {
  background-color: var(--primary-theme-color);
  min-height: 100vh;
  height: 100%;
  padding: 15%;
}

.Login .detail {
  color: var(--primary-font-color);
}

.Login .detail .header {
  font-size: 30px;
  font-weight: bold;
  color: var(--primary-font-color);
}

.Login .detail .header.darkblue {
  /* color: #004884; */
  color: var(--header-font-color);
}

.Login .fbLogin {
  text-align: center;
}

.Login .email-login-button {
  width: 90%;
  line-height: 32px;
  background-color: var(--primary-font-color);
  color: var(--primary-theme-color);
  border: 1px solid;
  display: inline-block;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
}

/* ========================================= END Login ========================================= */
/* ========================================= email-login ========================================= */

.email-login {
  height: 100vh;
  padding: 15%;
  text-align: center;
}

.email-login .email-input.ui.input {
  width: 100%;
}

.email-login .email-input input {
  border: 1px solid var(--primary-theme-color, black);
  /* border: 1px solid #5DBCD2; */
}

.email-login .err-message {
  color: var(--error-color);
}

.email-login .login-button.ui.button {
  width: 100%;
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border: var(--std-border);
  /* background-color: #5DBCD2;
  color: white; */
}

.email-login .sub-text {
  font-weight: bold;
}

/* ========================================= END email-login ========================================= */
/* ========================================= email-register ========================================= */

.email-register {
  height: 100vh;
  padding: 10%;
  text-align: center;
}

.email-register .email-input,
.email-register .first-name-input,
.email-register .last-name-input {
  width: 100%;
}

.email-register .email-input input,
.email-register .first-name-input input,
.email-register .last-name-input input {
  border: 1px solid var(--primary-theme-color, black);
}

.email-register .register-button.ui.button {
  width: 100%;
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border: var(--std-border);
  /* color: white; */
}

.email-register .err-message {
  color: var(--error-color);
}

.email-register-success {
  height: 100vh;
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.email-register-success .green {
  color: var(--success);
}

.email-register-success .sub-text {
  line-height: 1.6em;
}

/* ========================================= email-register ========================================= */
/* ========================================= FriendMessage ========================================= */

.FriendMessage {
  margin: 1%;
  margin-left: 3%;
}

.FriendMessage .field.friendName {
  margin: 0;
}

.FriendMessage .avatarImage {
  display: none !important;
}

.FriendMessage .inline.fields .field.content .bubble {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1.3em;
  background-color: var(--consent-form-bg-color);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 60%;
  border: var(--std-border);
}

.FriendMessage .inline.fields .sendDateTime {
  font-size: 12;
  padding: 0.5em;
  align-self: flex-end;
}

.MyMessage .inline.fields .field.content .call.icon,
.FriendMessage .inline.fields .field.content .call.icon {
  transform: rotate(90deg);
}

.FriendMessage .inline.fields .field.content .file {
  text-align: center;
  padding: 5px;
}

.FriendMessage .inline.fields .field.content .file .text {
  padding-top: 10px;
}

/* ========================================= END FriendMessage ========================================= */
/* ========================================= MyMessage ========================================= */

.MyMessage {
  margin: 1%;
  max-width: 60%;
}

.MyMessage .inline.fields .field.content {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* border: 1px solid; */
  background-color: var(--primary-theme-chat);
  /* background-color: rgba(118, 207, 227, 0.6); */
  border-radius: 1.3em;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 60%;
  border: var(--std-border);
}

.MyMessage .inline.fields .field.sendDateTime {
  font-size: 12;
  padding: 0.5em;
  text-align: initial;
  align-self: flex-end;
}

.MyMessage .fields {
  direction: rtl;
}

.MyMessage .inline.fields .field.content.selected {
  /* background-color: rgba(118, 207, 227, 1) */
  background-color: var(--primary-theme-chat);
}

.MyMessage .inline.fields .field.content .file {
  text-align: center;
  padding: 5px;
}

.MyMessage .inline.fields .field.content .file .text {
  padding-top: 10px;
}

/* ========================================= END MyMessage ========================================= */
/* ========================================= TimePatient ========================================= */
.TimePatient h1 {
  /* color: var(--diag-rule-header-font-color) */
}

.TimePatient .dimmable {
  height: 85vh;
}

.TimePatient .appointCard {
  /* display: flex; */
  /* align-items: flex-start; */
  /* background-color: white; */
  border-bottom: solid 1px;
  border-bottom: var(--diag-rule-header-font-color) solid 1px;
  padding: 10px;
  margin-bottom: -1px;
  /* justify-content: space-between; */
}

.TimePatient .appointCard .header-and-button {
  display: flex;
  justify-content: space-between;
}

.TimePatient .appointCard .header-and-button button {
  height: fit-content;
}

.TimePatient .appointCard h2 {
  color: var(--primary-theme-color);
}

.TimePatient .appointCard .location {
  margin-bottom: 5px;
}

.TimePatient .appointCard .changeButton {
  color: var(--primary-font-color);
  background-color: var(--error-color);
  font-weight: bold;
  text-align: center;
}

.TimePatient .appointCard .addButton {
  color: var(--primary-font-color);
  background-color: var(--primary-theme-color);
  font-weight: bold;
  text-align: center;
}

.TimePatientEdit .slotOptions {
  display: flex;
  padding: 3%;
  padding-top: 5%;
  padding-bottom: 5%;
  border-bottom: var(--primary-theme-chat, black) solid 1px;
}

.TimePatientEdit .slotOptions.active {
  background: var(--primary-theme-chat-half-light, lightgrey);
}

.TimePatientEdit .providerColumn {
  flex: 2;
}

.TimePatientEdit .timeColumn,
.TimePatientEdit .dateColumn {
  flex: 1;
}

.TimePatient .no-appoint {
  height: 50vh;
  text-align: center;
  margin-top: 50%;
  color: var(--primary-theme-color);
}

.TimePatient h1 {
  margin: 0;
  padding: 10px;
  /* color: var(--primary-theme-color); */
}

/* ========================================= END TimePatient ========================================= */
/* ========================================= ChatBox ========================================= */

.chat-box-wrapper {
  /* height: 100vh; */
  /* height: calc(100vh - 40px); */
  /* height: 100vh; */
  /* overflow: hidden; */
  /* background-color: var(--chat-bg); */
}

.ChatBox {
  /* height: calc(100vh - calc(100vh - 100%) - 90px); */
  height: calc(100% - 42px);
  width: 100vw;
  background-color: var(--chat-bg);
  overflow-y: auto;
}

.ChatBox .messageView {
  height: calc(100% - 50px);
  /* padding-top: 40px; */
  /* height: 100%; */
  overflow-y: auto;
}

.ChatBox .chat-loader {
  position: relative;
  padding: 20px;
}

.ChatBox .messageView > div:last-child {
  margin-bottom: 50px;
}

.ChatBox .ChatBoxChoice,
.ChatBox .ChatBoxChoice .button {
  background-color: var(--primary-font-color);
  color: var(--primary-theme-chat);
  /* background-color: #F2F2F2; */
  /* color: #019D9A; */
}

.ChatBox .ChatBoxChoice {
  display: none;
}

.ChatBox .textBox {
  display: flex;
  padding: 5px;

  /* z-index: 10; */

  height: 50px;
  /* position: fixed; */

  position: sticky;
  /* position: absolute; */

  /* position: -webkit-sticky; */

  /* width: 100%; */
  bottom: 0;
  background-color: var(--consent-form-bg-color);
}

.ChatBox .textBox .menu-div {
  display: flex;
}

.ChatBox .textBox textarea {
  font-size: 16px;
  border: 1;
  flex: 1;
  padding: 0.5em;
  align-self: center;
}

.ChatBox .menu-div .button,
.ChatBox .textBox .button {
  vertical-align: middle;
  background-color: transparent;
  color: var(--primary-theme-color);
}

.ChatBox .textBox textarea::-webkit-scrollbar {
  display: none;
}

.ChatBox .textBox textarea {
  resize: none;
  border: 1px solid;
  border: 1px solid var(--primary-theme-color);
  /* padding: 2%; */
  /* line-height: 0.8em; */
  /* height: 80%; */
  border-radius: 500rem;
  /* border-radius: 20px; */
}

.ChatBox .textBox textarea:focus {
  outline: 0;
}

/* ========================================= END ChatBox ========================================= */
/* ========================================= ChatList ========================================= */

.ChatList .row {
  padding: 5%;
  margin: 5%;
  display: flex;
  flex-direction: row;
  border: 2px solid #f2f2f2;
  box-shadow: 0px 5px lightgrey;
  border-radius: 10px;
}

.ChatList .row .form .fields {
  margin: 0;
}

.form .fields .field.filter,
.ChatList .row .detail .subDetail,
.ChatList .row .time {
  display: none !important;
}

.ChatList .row .detail .header {
  padding: 10px;
}

/* ========================================= END ChatList ========================================= */
/* ========================================= chat-page ========================================= */

.chat-page .no-user-id {
  margin-top: 50%;
  text-align: center;
}

.chat-page .dimmable {
  height: 85vh;
}

.chat-page .chat-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.chat-page .chat-list-item img.ui.fluid.image {
  width: 60px;
  height: 60px;
}

.chat-page .chat-list-item .content {
  margin-left: 8px;
}

.chat-page .ui.secondary.menu.chat-list-search {
  margin: 0;
  padding: 0;
}

.chat-page .ui.secondary.menu.chat-list-search .item {
  margin: 0;
  padding: 0;
  flex: 1;
}

.chat-page .ui.secondary.menu.chat-list-search .ui.input {
  margin: 8px;
  font-size: 16px;
}

/* ========================================= END chat-page ========================================= */
/* ========================================= official-account ========================================= */

.official-account-list .division-list,
.official-account .diag-rule-list-item {
  margin-left: 16px;
  margin-right: 16px;
}

.official-account-list .division-list .ui.label,
.official-account .diag-rule-list-item .ui.label {
  /* color: #004884;
  background: rgba(1, 178, 172, 0.2); */
  color: var(--header-font-color);
  background: var(--oa-bg-color);
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: normal;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: var(--std-border);
}

.official-account-list .ui.segment,
.official-account .ui.segment {
  border-radius: 15px;
  margin: 16px;
  background: var(--oa-bg-color-light);
  border: var(--std-border);
}

.official-account-list .ui.segment .ui.label,
.official-account .ui.segment .ui.label {
  background: transparent;
}

.official-account-list .sub-header,
.official-account .sub-header {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  color: var(--primary-font-color);
  background: var(--primary-theme-color);
}

.official-account-list .division-name,
.official-account .division-name {
  font-size: 16px;
  color: var(--header-font-color);
}

.official-account-list .ui.input,
.official-account .ui.input {
  margin: 8px;
}

.official-account-list .division-list,
.official-account .diag-rule-list {
  margin-bottom: 16px;
}

/* ========================================= END official-account ========================================= */
/* ========================================= diag-rule-search ========================================= */

.diag-rule-search .title {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.diag-rule-search .ui.input {
  margin: 8px;
  font-size: 16px;
}

.diag-rule-search .diag-rule-list-title {
  padding-left: 16px;
  padding-right: 16px;
}

.diag-rule-search .item.diag-rule-list-title {
  background: var(--primary-theme-color);
}

.diag-rule-search .diag-rule-list-title .form-title {
  font-weight: bold;
  color: var(--primary-font-color);
}

.diag-rule-search .diag-rule-list-title .account-title {
  font-weight: bold;
  text-align: right;
  color: var(--primary-font-color);
}

.diag-rule-search .diag-rule-list-item {
  margin-left: 16px;
  margin-right: 16px;
}

.diag-rule-search .diag-rule-list-item .ui.label {
  /* text-align: right;  */
  background: transparent;
}

.diag-rule .diag-rule-header {
  color: var(--diag-rule-header-font-color);
}

.diag-rule .ui.button {
  color: var(--primary-font-color);
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color, black);
}

/* ========================================= END diag-rule-search ========================================= */
/* ========================================= diag-form ========================================= */

.diag-form,
.diag-form-anonymous {
  margin-left: 5px;
  margin-right: 5px;
}

.diag-form .ui.label {
  margin: 4px;
  width: -webkit-fill-avaliable;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--diag-form-label-font-color);
  background-color: var(--diag-form-label-bg-color);
}

.diag-form .empty-div {
  float: left;
  clear: both;
}

.diag-form .question-section {
  display: flex;
  flex-direction: column;
}

.diag-form .section-row {
  width: 80%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 1%;
  border-radius: 10px;
}

.diag-form .block-display-text {
  font-weight: bold;
  text-align: left;
}

.diag-form .block-display-choices-true {
  text-align: right;
}

.diag-form .block-display-choices-false {
  text-align: right;
  color: green;
}

.diag-form .block-display-extra-intro {
  font-weight: bold;
  text-align: left;
}

.diag-form .extra-choices {
  font-weight: bold;
  margin-top: 3px;
  margin-bottom: 6px;
}

/* ========================================= END diag-form ========================================= */

.profile {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 8px;
}

.profile-create.wizard.profile-title {
  color: #1a6374;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
}

.profile.minus-top {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: -20px;
}

.profile .ui.active.transition.visible.inverted.dimmer {
  z-index: 2;
}

.profile img.ui.image.profile-image {
  padding: 3px;
  width: 132px;
  height: 132px;
  z-index: 1;
}

.profile img.ui.image.profile-imageM {
  padding: 3px;
  width: 132px;
  height: 132px;
}

.profile .profile-image-radius {
  background: black;
  width: 132px;
  height: 66px;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0 0 12rem 12rem;
  opacity: 0.6;
  padding: 3px;
  z-index: 1;
}

.profile .profile-image-edit {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.profile .ui.segment.profile-segment {
  background: var(--profile-segment-bg-color);
  border-radius: 15px;
  margin-top: -60px;
}

.profile .ui.segment.profile-segment-noimage {
  background: var(--profile-segment-bg-color);
  border-radius: 15px;
}

.profile .required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.profile .ui.segment.profile-segment p {
  font-size: 22px;
  color: var(--header-font-color);
  margin-top: 60px;
  text-align: center;
}

.profile .ui.segment.profile-segment-noimage p {
  font-size: 22px;
  color: var(--header-font-color);
  margin-top: 10px;
  text-align: center;
}

.profile .ui.segment.profile-segment .profile-segment-form {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -16px;
}

.profile .ui.segment.profile-segment .profile-segment-form label,
.profile .ui.segment.profile-segment-noimage .profile-segment-form label {
  color: var(--header-font-color);
}

.profile .profile-segment-noimage .allergy-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.profile .profile-segment-noimage .allergy-checkbox {
  margin-bottom: 10px;
}

.profile .profile-segment-noimage .allergy-checkbox .ui.checkbox label {
  font-weight: bolder !important;
}

.profile .profile-segment-noimage .allergy-result label {
  font-size: 18px;
  color: var(--header-font-color);
}

.profile .ui.segment.profile-segment .profile-segment-form .ui.input {
  font-size: 16px;
}

.profile .ui.segment .save-div {
  margin-top: 16px;
  text-align: center;
}

.profile .ui.segment .save-div button {
  width: 50%;
  color: var(--primary-font-color);
  background: var(--primary-theme-color);
  border: var(--std-border);
}

.profile .ui.segment .step-div {
  margin-top: 16px;
  text-align: center;
}

.profile .ui.segment .step-div button {
  width: 30%;
  color: var(--primary-font-color);
  background: var(--primary-theme-color);
  border: var(--std-border);
}

.profile .logout-div {
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.profile .logout-div .ui.button {
  width: 75%;
  border: 1px solid var(--primary-theme-color, black);
  background: var(--primary-font-color);
  color: var(--primary-theme-color);
}

.patient-select-appointment {
  padding: 3%;
}

.patient-select-appointment .button-container {
  display: flex;
  justify-content: space-evenly;
}

.mod-edit-select-appointment.ui.modal > .header {
  background-color: var(--error-color);
  color: var(--primary-font-color, white);
}

.mod-edit-select-appointment.ui.modal .button-group {
  display: flex;
  justify-content: center;
}

.ui.form .inline.field > .selection.dropdown,
.ui.form .inline.fields .field > .selection.dropdown {
  font-size: 16px;
}

.mod-edit-select-appointment .ui.form textarea {
  font-size: 16px;
}

.patient-payment,
.patient-payment-detail {
  height: 100%;
  background-color: var(--chat-bg);
}

.patient-payment-detail .text-content {
  text-align: center;
}

.patient-payment-detail .text-content h4 {
  margin: 0;
  color: black;
}

.patient-payment-detail .text-content.price {
  font-size: 48px;
  margin: 0;
  color: var(--header-font-color);
}

.patient-payment-detail .pay-btn.ui.button {
  position: absolute;
  bottom: 0;
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.patient-payment-detail .date-time-wrapper {
  display: flex;
  justify-content: space-around;
}

.patient-payment .ui.secondary.menu-bar.menu {
  position: sticky;
  top: 0;
}

.patient-payment .ui.secondary.pointing.menu {
  /* border-bottom: 0; */
  background-color: var(--primary-font-color);
  position: sticky;
  top: 40px;
}

.patient-payment .ui.secondary.pointing.menu .item {
  /* width: 33.33%; */
  width: 50%;
  justify-content: center;
}

.patient-payment .ui.secondary.pointing.menu .active.item {
  border-color: var(--header-font-color);
}

.patient-payment .sub-patient-payment-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.patient-payment .sub-patient-payment {
  background-color: var(--primary-font-color, white);
  padding: 10px;
}

.patient-payment .sub-patient-payment .content-row {
  display: flex;
  justify-content: space-between;
}

.patient-payment .sub-patient-payment .price-text {
  margin: 0;
  color: var(--header-font-color);
}

.patient-payment .sub-patient-payment .pay-btn {
  text-align: end;
}

.patient-payment .sub-patient-payment .pay-btn .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.patient-payment .dimmable-area {
  height: 85vh;
}

.patient-payment .dimmable-area.hidden {
  height: 0;
}

.patient-payment .dimmable-area .ui.dimmer {
  background-color: transparent;
}

.patient-payment h2 {
  color: var(--header-font-color);
}

.background-consent {
  height: 100%;
  width: 100vw;
  text-align: left;
  display: table;
}

.consent-header {
  display: flex;
  margin: 15px 10px 10px 10px;
  padding: 10px;
}

.consent-header .consent-header-left {
  flex: 1;
  color: var(--primary-theme-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.consent-header .consent-header-right {
  flex: 1;
}

.consent-header .consent-header-title {
  color: var(--primary-theme-color);
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.consent-header .consent-header-title .consent-header-title-redline {
  min-width: 50vw;
  min-height: 3px;
  background-color: red;
  margin-top: 8px;
}

.consent-form .content {
  margin: 20px;
  padding-bottom: 100px;
}

.consent-form .ui.segment {
  position: fixed;
  width: 100vw;
  bottom: 0;
  background-color: var(--consent-form-bg-color);
}

.consent-form .ui.segment .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border-radius: 30px;
}

.consent-form .ui.segment div {
  text-align: center;
  margin: 15px;
}

.consent-form .content .content-item .answer-options {
  text-align: center;
}

.consent-form .content .content-item .answer-options label {
  color: var(--primary-theme-color);
  font-weight: bold;
}

.consent-form p {
  text-indent: 40px;
}

.consent-form p,
.consent-form h4,
.consent-form h3 {
  line-height: 1.5em;
}

.consent-form h4,
.consent-form h3 {
  color: var(--primary-theme-color);
}

.consent-form p:last-child {
  margin: 0 0 1em;
}

.mod-consent-form.ui.modal {
  padding: 15px;
  color: var(--primary-theme-color);
}

.mod-consent-form.ui.modal .ui.button {
  margin: 5px;
  border: solid 2px var(--primary-theme-color);
  color: var(--primary-theme-color);
  background: transparent;
}

.mod-consent-form.ui.modal .ui.button:active {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.mod-consent-form.ui.modal .btn-group {
  display: flex;
}

/* ========================================= address-confirm ========================================= */

.address-confirm {
}

.address-confirm .content {
  padding: 5%;
}

.address-confirm .content p {
  line-height: 1.5em;
}

.address-confirm .content .bottom-border.ui.input input {
  border: 0;
  border-bottom: 1px solid grey;
  border-radius: 0;
  text-align: right;
  color: var(--primary-theme-color);
}

.address-confirm .content .receiver h5,
.address-confirm .content .contact-tel h5 {
  white-space: nowrap;
  margin: 0;
}

.address-confirm .content .receiver,
.address-confirm .content .contact-tel {
  display: flex;
  align-items: center;
}

.address-confirm .content .receiver-input,
.address-confirm .content .contact-tel-input {
  width: 100%;
  font-size: 16px;
}

.address-confirm .hos-address {
  margin-left: 27px;
}

.address-confirm .address {
  display: flex;
  justify-content: space-between;
}

.address-confirm .ui.checkbox label,
.address-confirm .ui.checkbox input:focus ~ label,
.address-confirm .ui.segment i {
  color: var(--primary-theme-color);
}

.address-confirm .btn-group {
  display: flex;
  padding: 20px;
  /* display: flex; 
  justify-content: space-between; 
  position: fixed; 
  bottom: 0; 
  width: 100%; 
  padding: 20px;
  background-color: white; */
}

.address-confirm .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border-radius: 10px;
}

.address-confirm .ui.button.inverted-btn {
  background-color: transparent;
  border: 2px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
}

/* ========================================= END address-confirm ========================================= */
/* ========================================= select-address ========================================= */

.select-address {
  background-color: var(--secondary-bg-color, transparent);
  height: 100%;
}

.select-address .selected-address {
  padding: 5%;
  background-color: var(--primary-font-color, white);
}

.select-address .add-address,
.select-address i {
  color: var(--primary-theme-color);
}

.select-address .section-header {
  padding: 5%;
}

.select-address .add-new-address {
  background: var(--primary-font-color, white);
}

.select-address .add-new-address > div {
  display: flex;
  justify-content: space-between;
  padding: 3%;
  padding-left: 20px;
  padding-right: 20px;
}

.select-address .address-list {
  margin-bottom: 10px;
  background-color: var(--primary-font-color, white);
  display: flex;
  justify-content: space-between;
}

.select-address .address-list .address-detail {
  padding: 20px 1% 20px 20px;
}

.select-address .address-list .edit-address-detail {
  padding: 20px 20px 20px 1%;
  color: var(--primary-theme-color);
}

/* =========================================  END select-address ========================================= */
/* ========================================= edit-address ========================================= */

.edit-address {
  height: 100vh;
  background-color: var(--secondary-bg-color, transparent);
}

.edit-address .default-check {
  padding: 20px;
}

.edit-address .address-detail {
  display: flex;
  align-items: center;
  background-color: var(--primary-font-color, transparent);
  padding-left: 20px;
  margin: 10px;
  margin-top: 0px;
}

.edit-address .address-detail label {
  margin: 0;
  height: 20px;
  white-space: nowrap;
}

.edit-address .address-detail-dropdown {
  width: 100%;
}

.edit-address .address-detail-dropdown .ui.dropdown {
  border: 0;
  text-align: right;
}

.edit-address .address-detail-dropdown .ui.search.dropdown > input.search {
  text-align: right;
}

.edit-address .address-detail-dropdown .ui.dropdown .menu {
  border: 0;
}

.edit-address .address-detail-dropdown .ui.dropdown .menu .item {
  text-align: right;
}

.edit-address .address-detail-dropdown .ui.dropdown input {
  font-size: 16px;
}

.edit-address .address-detail.postcode {
  border: 1px solid var(--primary-theme-color);
}

.edit-address .address-detail-input {
  width: 100%;
}

.edit-address .address-detail-input input {
  text-align: right;
  font-size: 16px;
  border: 0;
}

.edit-address .address-info {
  display: flex;
  align-items: center;
  margin: 30px;
  margin-right: 10px;
  margin-top: 10px;
}

.edit-address .address-info label {
  white-space: nowrap;
  margin: 0;
}

.edit-address .address-info-input {
  width: 100%;
}

.edit-address .bottom-border.ui.input input {
  border: 0;
  border-bottom: 1px solid grey;
  border-radius: 0;
  text-align: right;
  background-color: transparent;
  font-size: 16px;
}

.edit-address .delete-address {
  padding: 10px;
  padding-left: 0px;
  margin: 10px;
  background-color: var(--primary-font-color, transparent);
}

.edit-address .delete-address > span {
  background-color: var(--primary-font-color, transparent);
  padding-left: 20px;
  color: var(--primary-theme-color);
}

.edit-address .btn-group {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5%;
}

.edit-address .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border-radius: 10px;
}

.edit-address .ui.button.inverted-btn {
  background-color: transparent;
  border: 2px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
}

/* ========================================= END edit-address ========================================= */
/* ========================================= ShippingList ========================================= */

.shippinglist {
  width: 100%;
  background-color: white;
  border-color:  #0072bc;
  border-bottom-width: 3px;
  z-index: 1;
  position: f;
}

.shippinglist .ui.segment.active.tab {
  background-color: white;
  border-width: 0px;
  box-shadow: none;
  margin: 0px;
  overflow: scroll;
}
.shippinglist .ui.secondary.pointing.menu .active.item {
  border-color: var(--primary-theme-color);
  border-bottom-width: 3px;
  color: var(--primary-theme-color);
}
.shippinglist .ui.pointing.secondary.menu {
  border-bottom: 0;
  margin-bottom: 2.5px;
  width: 100%;
  justify-content: center;
}

.shippinglist .ui.secondary.pointing.menu .item {
  border-color: #888787;
  padding: 1rem;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: #888787;
  border-bottom-width: 3px;
}

.shippinglist .item {
  border-color: #888787;
  border-bottom-width: 3px;
  color: #888787;

}

.shippinglist .ui.menu {
  font-size: medium;
}