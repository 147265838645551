
.content-header {
  background: linear-gradient(#1AB3CB,#1ab3cb00);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content-detail {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background: #FFFFFF;
    height: 100%;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ui.form .field.field input:-webkit-autofill {
      background: #E3F3F9;
    }

    .ui.form .field>label {
      color: var(--primary-font-color);
    }

    .ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
      background: #E5E6E6;
      opacity: 0.7;
    }

    .selection.dropdown {
      background: #E3F3F9;
      border-radius: 20px;
      border: 0;
    }
    .ui.form .field .ui.input input, .ui.form .fields .field .ui.input input {
      background: #E3F3F9;
      border-radius: 20px;
      border: 0;
      &:disabled {
        background: #E5E6E6;
        opacity: 0.7;
      }
    }

    .ui.form .disabled.field {
      opacity: 1;
    }

    .ui.form .field.disabled>label {
      opacity: 1;
    }

    .ui.disabled.input {
      opacity: 1;
    }

    .date-picker {
      border: 0px;
      width: 100%;
      .MuiOutlinedInput-root {
        flex-direction: row-reverse ;
        margin: 0px;
        padding: 0px;
        width: 100%;
        border: 0px;
        .MuiOutlinedInput-input {
          padding: 5px 0px 5px 14px !important;
          background: transparent;
          border-radius: 50px;
          border: 0px;
          width: 100%;
        }
        .MuiOutlinedInput-notchedOutline {
          border: 0px;
        }
      }
    }

    .chat-search {
      margin-bottom: 10px;
      .ui.input {
        width: 100%;
      }
    }

    .chat-page::-webkit-scrollbar {
      display: none;
    }

    .chat-page {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: auto;
        height: calc(100vh - 200px);
        padding-bottom: 50px;
        ::-webkit-scrollbar {
          display: none;
        }

        .dimmable {
          height: auto;
        }

      .chat-list-items {
        display: flex;
        padding: 15px 5px;
        border-bottom: 1px solid var(--primary-font-color);
        .chat-list-items-image {
          display: flex;
          min-height: 40px;
          border: 5px solid white;
          padding: 7px;
          background-color: #FFF0E0;
          border-radius: 15px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        }
        .chat-list-items-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          & div:nth-child(1) {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #2B3336;
          width: 100%;
          }
          & div:nth-child(2) {
            display: flex;
            font-size: 10px;
            font-weight: bold;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            padding: 1px;
            width: 21px;
            height: 21px;
            aspect-ratio: 1/1;
            color: white;
            background-color: red;
            }
        }
      }
    }

    .ChatBox {
      height: calc(100vh - 75px);
      background-color: transparent;
      .textBox {
        width: 100%;
        bottom: 0;
      }
      .messageView {
        padding: 10px 15px 0 15px;
        margin-top: 0;
        // height: calc(100vh - 110px);
        .chat-loader {
          padding-bottom: 25px;
        }
      }
      .menu-div {
        padding: 0 5px;
      }
    }
  
    .FriendMessage {
      .field.friendName {
        padding-bottom: 3px;
      }
      .inline.fields .field.content {
        border-radius: 1.5em;
        padding: 0.8rem 1.2rem;
        border-top-left-radius: 0;
      }
    }
  
    .MyMessage .inline.fields .field.content {
      border-radius: 1.5em;
      padding: 0.8rem 1.2rem;
      border-top-right-radius: 0;
      // background-color: rgb(217, 117, 217);
    }

    .content-button {
      border-radius: 20px;
      min-width: 200px;
      min-height: 45px;
      background: var(--primary-theme-color);
      display: flex;
      align-items: center;
      color: white;
      font-weight: bold;
      justify-content: center;
    }

    .content-button.disable {
      border-radius: 20px;
      min-width: 200px;
      min-height: 45px;
      background: #D9D9D9;
      display: flex;
      align-items: center;
      color: white;
      opacity: 0.8;
      font-weight: bold;
      justify-content: center;
    }
    
    .content-common-button {
      border-radius: 20px;
      background: var(--primary-theme-color);
      color: white;
      font-weight: bold;
      margin-left: 10px;
      padding: 8px 0;
    }

    .tab-custom {
      background: transparent;
    }

    .card-appointment-detail {
      font-size: 14px;
      display: flex;
      margin-right: 10px;
      i.circular.icon{
        background: #B7D7E2;
        color: #8BB6C6;
      }
    }

    .card-appointment-telemed {
      display: flex;
      padding: 0px 6px;
      border-radius: 20px;
      font-size: 9px;
      color: white;
    }

    .form-margin {
      margin: 0 0 1em;
      .ui.selection.dropdown {
        padding: 1rem 1rem;
        & .icon {
          top: 1.1rem;
          right: -0.05rem;
          font-size: 1rem;
          width: 2.67142857em;
          opacity: 0.5;
          position: absolute;
          &.calendar {
            font-size: 1.25rem;
            top: 1rem;
            right: -0.35rem;
            opacity: 0.5 !important;
          }
        }
      }
    }

    .form-margin.error .ui.selection.dropdown {
      border: 1px solid red;
    }

    .h1, h2, h3  {
      color:  var(--primary-theme-color);
    }
  }

  .appointment-card-summary {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    border-radius: 15px;
    background: #E3F3F9;
    // opacity: 0.5;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 15px;
    margin: 20px 0px;
    .card-appointment-detail {
      font-size: 14px;
      display: flex;
      margin: 10px;
      opacity: 1;
      i.circular.icon{
        background: #B7D7E2;
        color: #8BB6C6;
      }
    }
  
    .card-appointment-telemed {
      opacity: 1;
      display: flex;
      padding: 0px 6px;
      border-radius: 20px;
      font-size: 9px;
      color: white;
    }
  }

  .content-input-bottom {
    .ui.fluid.input {
      width: 100%;
    }

    .ui.fluid.input>input{
      width: 100%;
      border: none;
      border-radius: 0px;
      border-bottom: 2px solid #D2D2D2;
    }
  }
  

  .appoint-search {
    border-radius: 20px;
    border: 1px solid #A2BFC9 ;
    padding: 2px;
    .ui.fluid.input>input {
      border: 0px solid #A2BFC9 ;
      background: transparent;
      margin-left: 10px;
    }
    .ui.icon.input>i.icon {
      background: #1ABDD4;
      border-radius: 20px;
      color: white;
    }
    ::placeholder {
      color: #8BB6C6;
      margin-left: 10px;
    }
  }

  .appointment-card-box{
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    background: white;
    .division {
        display: flex;
        width: 50px;
        height: 50px;
        border: 5px solid white;
        padding: 5px;
        background-color: #FFF0E0;
        border-radius: 15px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }

  .card-appointment {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    .MuiAccordion-root {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
    }
    .MuiAccordionDetails-root{
      padding: 0px 16px 16px;
    }
    .accordion-summary {
      display: flex;
      flex-direction: column;
      width: 100%;
      & > div {
        font-size: 14px;
      }
    }
    .accordiond-details {
      display: flex;
      flex-direction: column;
      
      & > div {
        font-size: 14px;
      }
    }
  }

  .appointment-selected-time-slot{
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 10px;

    .MuiAccordion-root {
      box-shadow: none;
      border-radius: 15px;
    }
    .MuiAccordion-root::before {
      background-color: transparent;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 20px 0px 10px;
    }
    .MuiAccordionDetails-root{
      padding: 0px 16px 16px;
    }
    .accordion-summary {
      display: flex;
      flex-direction: column;
      width: 100%;
      & > div {
        font-size: 14px;
      }
    }
    .i.icon {
      color: #746A6A;
    }
  }

  .card-selected-doctor {
    position: sticky;
    z-index: 1000;
    background: white;
    border-radius: 15px;
    margin: 20px 15px 0px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

    .ui.image {
      border-radius: 10px;
    }
  }

  .card-selected-division {
    position: sticky;
    z-index: 1000;
    background: white;
    border-radius: 15px;
    margin: 20px 15px 0px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    .division {
      display: flex;
      border: 5px solid white;
      padding: 5px;
      background-color: #FFF0E0;
      border-radius: 15px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }
  .tab-selected-telemed{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
    font-weight: bold;
    border-Bottom: 2px solid #D9D9D966;
    font-size: 16px;
    padding: 10px 0px;
    color: var(--primary-font-color);
  }

  .rc_body-weekdays {
    border-bottom: none;
  }

  .rc_header_nav {
    box-shadow: none
  }

  .rc_root {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  }
 
  .content-header-laban {
    border-left: 4px solid var(--primary-theme-color);
    padding: 5px 10px;
    font-weight: bold;
    margin: 4px 0px;
    color: var(--primary-font-color);
    
  }

  .content-input-detail {
    display : flex ;
    justify-content: center;
    width: 100%;
    margin: 25% 0px 35%;
    .ui.fluid.input {
      width: 100%;
    }

    .ui.fluid.input>input{
      width: 100%;
      border: none;
      border-radius: 0px;
      border-bottom: 2px solid #1BBDD4;
    }
  }
}

/////////////////////////


.header {
  margin: 3% 10px;
  display: grid;
  height: 40px;
  grid-template-columns: 35px auto 35px;
  color: #FFFFFF;
  align-items: center;
  font-size: 20px;
}

.content-filed-input {
  border-radius: 30px;
  padding: 5px;
  display: flex;
  background: #B7D7E2;
  align-items: center;
  margin-bottom: 10px;
  .ui.input{
    width: 100%;
  }

  .ui.input > input {
    background: transparent;
    border: transparent;
  }

  .ui.input>input:focus {
    background: transparent;
  }

  & .error-filed {
    border: 1px solid red;
  }

}
.input-error-message {
  color: red;
}

.content-filed-radio {
  .ui.checkbox input.hidden+label {
    color: var(--primary-font-color);
  }

  &.ui.radio.checkbox label:before {
    background-color: #C1E3EF;
    border: 0px;
  }

  &.ui.radio.checkbox input:checked~label:before {
    background-color: #C1E3EF;
  }

  &.ui.radio.checkbox input:checked~label:after {
    color: var(--primary-theme-color);
    background-color: var(--primary-theme-color)
  }

  &.ui.radio.checkbox input:focus:checked~label:after {
    background-color: var(--primary-theme-color);
  }

  &.ui.radio.checkbox input:focus:checked~label:before {
    background-color: #C1E3EF;
  }
}

.content-filed-circular {
  border-radius: 20px;
  width: 30px;
  height: 30px;
  background: var(--primary-theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-button {
  border-radius: 20px;
  min-width: 200px;
  min-height: 45px;
  background: var(--primary-theme-color);
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  justify-content: center;
}

.content-button.disable {
  border-radius: 20px;
  min-width: 200px;
  min-height: 45px;
  background: #D9D9D9;
  display: flex;
  align-items: center;
  color: white;
  opacity: 0.8;
  font-weight: bold;
  justify-content: center;
}

.content-common-button {
  border-radius: 20px;
  background: var(--primary-theme-color);
  color: white;
  font-weight: bold;
  margin-left: 10px;
  padding: 8px 0;
}

.otpContainer {
  margin: 10px auto;
}

.otpInput {
  width: 45px ;
  height: 60px;
  margin: 0 5px;
  font-size: 2rem;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #A2BFC9;
  color: #A2BFC9;
  &:focus {
    border-color: var(--primary-theme-color);
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
}


.navigation-bottom {
  position: fixed;
  // bottom: 0;
  height: 63px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  z-index: 1000;
  background-color: white;

  & > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #9AB4BD;
    font-weight: bold;
    font-size: 0.8rem;

    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: top 0.3s ease, width 0.3s ease, height 0.3s ease;
      height: 30px;
    }

    div:nth-child(2) {
      top: 29px;
    }

    &.active {
      div:nth-child(2),
      i {
        color: var(--primary-theme-color);
      }
    }

   
  }
}

.div-center-bottom {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 30px;
  flex: 1;
}
.ishealth-buttom-consent {
  display: flex;
  justify-content: center;
  margin: 20px 5px;
  width: 100%;
  & > div {
    border-radius: 20px;
    min-height: 45px;
    background: var(--primary-theme-color);
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    justify-content: center;

    & .disable {
      opacity: 0.4;
    }

    & .backward {
      background: transparent;
      color: var(--primary-theme-color);
      border: 1px solid var(--primary-theme-color);
    }
  }

  & .ui.button {
    border-radius: 20px;
    width: 100%;
    min-height: 45px;
    background: var(--primary-theme-color);
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    justify-content: center;
  }

  & .ui.button:hover {
    background: var(--primary-theme-color);
    color: white;
  }
}

.count-unread-noti {
  display: flex;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 1px;
  width: 21px;
  height: 21px;
  aspect-ratio: 1/1;
  color: white;
  background-color: red;
}

.content-item .answer-options label {
  color: var(--primary-theme-color);
  font-weight: bold;
}

.ishealth-buttom {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  & > div {
    border-radius: 20px;
    min-width: 200px;
    min-height: 45px;
    background: var(--primary-theme-color);
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    justify-content: center;

    & .disable {
      opacity: 0.4;
    }

    & .backward {
      background: transparent;
      color: var(--primary-theme-color);
      border: 1px solid var(--primary-theme-color);
    }
  }

  & .ui.button {
    border-radius: 20px;
    min-width: 200px;
    min-height: 45px;
    background: var(--primary-theme-color);
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    justify-content: center;
  }

  & .ui.button:hover {
    background: var(--primary-theme-color);
    color: white;
  }
}
